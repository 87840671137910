<template>
  <div
    :class="[
      'page',
      {'page--bottom-indent': isApprovalsPage }
    ]"
  >
    <div class="ticket-head">
      <esmp-tabs
        key="category"
        :value="activeCategory"
        :active-sub-tab-id="activeSubCategory"
        @on-click="setCategory"
        @on-click-sub-tab="setCategory(null, $event)"
      >
        <template v-for="cat in categoryWithCounters">
          <esmp-tab-pane
            v-if="tabIsShown(cat.id)"
            :label="cat.name"
            :key="cat.id"
            :name="cat.id"
            :children="cat.children"
          />
        </template>
      </esmp-tabs>
      <div class="ticket-head__right">
        <div class="ticket-head__filters ticket-head__filters--count">
          <esmp-button
            class="ticket-head__filters-btn"
            view="function"
          >
            Количество записей: {{ ticketsPerPage }}
          </esmp-button>
          <div class="ticket-head__filters-list">
            <a
              :class="[
                'ticket-head__filters-item',
                { 'ticket-head__filters-item--active': ticketsPerPage === item },
              ]"
              v-for="item in ticketsPerPageList"
              :key="item"
              @click="setTicketsPerPage(item)"
            >
              {{ item }} записей
            </a>
          </div>
        </div>
        <div class="ticket-head__filters">
          <esmp-button
            class="ticket-head__filters-btn"
            view="function"
            icon="sort"
            icon-position="left"
          >
            Сортировка
          </esmp-button>
          <div class="ticket-head__filters-list">
            <a
              :class="[
                'ticket-head__filters-item',
                { 'ticket-head__filters-item--active': activeSortType.id === type.id },
              ]"
              v-for="type in sortTypes"
              :key="type.id"
              @click="activeSortType = type"
            >
              {{ type.name }}
            </a>
          </div>
        </div>
        <div v-if="isViewTypesShowed" class="ticket-head__view">
          <div
            v-for="view in viewTypes"
            :class="['ticket-head__view-item', { 'ticket-head__view-item--active' : activeView === view }]"
            :key="view"
            @click="setActiveView(view)"
          >
            <esmp-icon :name="`ticket-${view}`" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="tickets" class="tickets-block">
      <div v-if="activeView === 'line' && !fillerBlockIsShown" class="tickets-head">
        <div class="tickets-head__body">
          <div class="tickets-head__item">
            Название, номер, дата создания
          </div>
          <div class="tickets-head__item">
            Тема и описание
          </div>
          <div class="tickets-head__item">
            Исполнитель
          </div>
          <div class="tickets-head__item tickets-head__item--right">
            Детали
          </div>
          <div class="tickets-head__item tickets-head__item--right">
            Статус
          </div>
        </div>
        <div class="tickets-head__actions">
          <div class="tickets-head__item tickets-head__item--center">
            Действия
          </div>
        </div>
      </div>
      <template v-if="groupedTickets && groupedTickets.length">
        <template v-for="group in groupedTickets">
          <h2 v-if="group.title" :key="`title-${group.id}`">
            {{ group.title }}
          </h2>

          <div :class="['tickets-list', `tickets-list--${activeView}`]" :key="`list-${group.id}`">
            <ticket-item
              v-for="ticket in group.tickets"
              :key="`${ticket.id}_${ticket.approvalId}`"
              :ticket="ticket"
              :view="activeView"
              :is-approval="isApprovalsPage"
              :selected-ticket-ids.sync="selectedTicketIds"
              @fetch-tickets="getTicketsList(false, true)"
            />
          </div>
        </template>
        <div
          class="tickets-more"
          v-if="isHasMoreBtnShowed"
        >
          <esmp-button
            size="medium"
            @click="getTicketsList(true)"
          >
            Загрузить еще
          </esmp-button>
        </div>
      </template>
    </div>
    <filler-block
      v-if="fillerBlockIsShown"
      is-big
      icon="empty-approval"
      :icon-width="204"
      :title="fillerBlockTitle"
      :subtitle="fillerBlockSubTitle"
    />
    <div
      v-if="tickets && isApprovalsPage"
      :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]"
    >
      <esmp-button
        class="page__action-button"
        size="medium"
        :disabled="!tickets.length"
        @click="selectAll"
      >
        {{ selectAllButtonText }}
      </esmp-button>
      <esmp-button
        class="page__action-button"
        size="medium"
        view="outline"
        :disabled="!selectedTicketIds.length"
        @click="showModalTicketApproval(true)"
      >
        {{ approveButtonText }}
      </esmp-button>
      <esmp-button
        class="page__action-button"
        size="medium"
        view="outline"
        :disabled="!selectedTicketIds.length"
        @click="showModalTicketApproval(false)"
      >
        {{ cancelButtonText }}
      </esmp-button>
      <modal-ticket-approval
        :is-show="showModals.ModalTicketApproval"
        is-multi
        :is-approve="isApprove"
        :tickets="selectedTickets"
        @hide-modal="hideModal"
        @fetch-tickets="getTicketsList(false, true)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  CATEGORY_LABELS,
  SORT_TYPES,
  VIEW_TYPES,
  TICKETS_PER_PAGE_LIST,
} from '@/constants/ticket';
import TicketItem from '@/components/ticket-item';
import FillerBlock from '@/components/filler-block';
import { WINDOW_SIZES } from '@/constants/view-options';
import { getStatus } from '@/helpers/status';

const ModalTicketApproval = () => import('@/components/modals/modal-ticket-approval');

const approvalTabId = 'myagree';
const activeTabId = 'active';

export default {
  name: 'Tickets',
  components: {
    TicketItem,
    ModalTicketApproval,
    FillerBlock,
  },
  data() {
    return {
      sortTypes: SORT_TYPES,
      viewTypes: VIEW_TYPES,
      ticketsPerPageList: TICKETS_PER_PAGE_LIST,
      selectedTicketIds: [],
      activeSubCategory: null,
      // TODO: удалить, когда на бэке смогут присылать количество тикетов для подкатегорий
      isNoMoreTickets: false,
      showModals: {
        ModalTicketApproval: false,
      },
      isApprove: true,
    };
  },
  computed: {
    ...mapState('viewOptions', ['windowSizes']),
    ...mapState('ticket', [
      'tickets',
      'counters',
      'activeView',
      'ticketsPerPage',
      'ticketSortType',
      'approvalSortType',
    ]),
    ...mapState('sidebar', ['isCollapsedMenu']),
    ...mapState('system', ['categories']),
    isTicketsPage() {
      return this.$route.name === 'Tickets';
    },
    isApprovalsPage() {
      return this.$route.name === 'Approvals';
    },
    fillerBlockTitle() {
      return this.isApprovalsPage ? 'Согласование заявок' : 'Заявки';
    },
    fillerBlockSubTitle() {
      return this.isApprovalsPage
        ? 'Нет заявок, требующих Вашего согласования'
        : 'У вас нет ни одной заявки <br>в этой категории';
    },
    isViewTypesShowed() {
      return this.windowSizes.current > WINDOW_SIZES.tabletPortrait;
    },
    categoryWithCounters() {
      const getName = (categoryName) => {
        let result = CATEGORY_LABELS[categoryName];

        if (Number.isInteger(this.counters[categoryName])) {
          result += ` ${this.counters[categoryName]}`;
        }

        return result;
      };

      return this.categories.map((category) => ({
        id: category.name,
        name: getName(category.name),
        children: category.children
          ? category.children.map((child) => ({
            id: child.name,
            name: CATEGORY_LABELS[child.name],
          }))
          : [],
      }));
    },
    activeCategory() {
      if (this.isApprovalsPage) return approvalTabId;
      return this.$route.params.category || activeTabId;
    },
    isHasMoreBtnShowed() {
      if (this.activeSubCategory) {
        return !this.isNoMoreTickets && !(this.tickets.length % this.ticketsPerPage);
      }
      return this.tickets?.length
        && (this.counters[this.activeCategory] > this.tickets.length);
    },
    fillerBlockIsShown() {
      return !this.counters[this.activeCategory];
    },
    isAllSelected() {
      return this.tickets?.length
        && this.selectedTicketIds?.length
        && (this.selectedTicketIds.length === this.tickets.length);
    },
    groupedTickets() {
      const groups = [];
      if (this.activeSortType.id === SORT_TYPES[2].id) {
        this.tickets.forEach((ticket) => {
          const groupId = ticket.serviceId;
          const group = groups.find((g) => g.id === groupId);
          if (group) {
            group.tickets.push(ticket);
          } else {
            groups.push({
              id: groupId,
              title: ticket.service,
              tickets: [ticket],
            });
          }
        });
      } else if (this.activeSortType.id === SORT_TYPES[3].id) {
        this.tickets.forEach((ticket) => {
          const status = getStatus(ticket.state);
          const groupId = status.name;
          const group = groups.find((g) => g.id === groupId);
          if (group) {
            group.tickets.push(ticket);
          } else {
            groups.push({
              id: groupId,
              title: status.name,
              tickets: [ticket],
            });
          }
        });
      } else {
        groups.push({
          id: 1,
          title: '',
          tickets: this.tickets,
        });
      }

      return groups;
    },
    selectedTickets() {
      return this.tickets?.filter((t) => this.selectedTicketIds.includes(t.id)) || [];
    },
    selectAllButtonText() {
      const countOfTickets = this.tickets?.length || 0;
      return this.isAllSelected ? `Снять выделение (${countOfTickets})` : `Выделить все (${countOfTickets})`;
    },
    selectedTicketIdsButtonText() {
      return this.selectedTicketIds?.length ? ` (${this.selectedTicketIds.length})` : '';
    },
    approveButtonText() {
      return `Согласовать${this.selectedTicketIdsButtonText}`;
    },
    cancelButtonText() {
      return `Отклонить${this.selectedTicketIdsButtonText}`;
    },
    activeSortType: {
      get() {
        return this.isApprovalsPage ? this.approvalSortType : this.ticketSortType;
      },
      set(value) {
        if (this.isApprovalsPage) {
          this.setApprovalSortType(value);
        } else {
          this.setTicketSortType(value);
        }
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('ticket', [
      'getTickets',
      'fetchCounters',
      'setActiveView',
      'setTicketsPerPage',
      'setTicketSortType',
      'setApprovalSortType',
    ]),
    hideModal(payload) {
      if (this.showModals[payload.modalName]) {
        this.showModals[payload.modalName] = payload.newValue;
      }
    },
    showModalTicketApproval(isApprove) {
      this.isApprove = isApprove;
      this.showModals.ModalTicketApproval = true;
    },
    setCategory(category, subCategory) {
      if (this.isTicketsPage) {
        if (subCategory) {
          this.activeSubCategory = subCategory;
          this.getTicketsList(false, false);
        } else {
          this.$router.push({
            name: 'Tickets',
            params: { category },
          });
        }
      }
    },
    getTicketsList(isMore, isUpdate) {
      this.setLoading({ key: 'app', value: true });
      this.selectedTicketIds = [];
      Promise.all([
        this.fetchCounters(),
        this.getTickets({
          sortBy: this.activeSortType.id,
          category: this.activeCategory,
          offset: isMore ? this.tickets.length : 0,
          isMore,
          limit: isUpdate ? this.tickets.length : this.ticketsPerPage,
          isActive: this.activeSubCategory === 'active',
        }).then((data) => {
          this.isNoMoreTickets = !Array.isArray(data) || data?.length === 0;
        }),
      ]).finally(() => {
        this.setLoading({ key: 'app', value: false });
      });
    },
    selectAll() {
      this.selectedTicketIds = this.isAllSelected ? [] : this.tickets.map((t) => t.id);
    },
    tabIsShown(id) {
      return this.isApprovalsPage ? id === approvalTabId : id !== approvalTabId;
    },
    setDefaultSubCategory(parentCategory) {
      const category = this.categories.find((cat) => cat.name === parentCategory);
      this.activeSubCategory = category.children ? category.children[0].name : null;
    },
  },
  watch: {
    activeCategory(value) {
      this.setDefaultSubCategory(value);
      this.getTicketsList();
    },
    activeSortType() {
      this.getTicketsList();
    },
    ticketsPerPage() {
      this.getTicketsList();
    },
    isViewTypesShowed: {
      handler(val) {
        if (!val) this.setActiveView('card');
      },
      immediate: true,
    },
  },
  created() {
    this.setDefaultSubCategory(this.activeCategory);
    this.getTicketsList();
  },
};
</script>
